<template>
  <div>
    <MainHeaderAlt />

    <article class="container mx-auto px-4 py-20">
      <h1 class="text-xl text-prasset-green-500">
        Privacy
      </h1>
      <p class="my-3 font-medium">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec sed nibh imperdiet enim imperdiet vehicula non at est. Sed suscipit tincidunt orci, at fermentum nisi sagittis at. Donec tempor cursus arcu, eget sodales arcu blandit commodo. Quisque sit amet interdum nulla, eget convallis eros. Sed in libero finibus, vehicula tellus ac, rutrum lacus. Suspendisse tincidunt massa a euismod faucibus. Sed at pharetra dolor. Nunc vitae tristique mi. Curabitur luctus sit amet sem at commodo. Vivamus congue fringilla nulla, eget pulvinar elit vestibulum ut. Nam nec bibendum metus. Nullam aliquet egestas lacinia. Proin a scelerisque orci, aliquam varius lorem. Pellentesque rhoncus, turpis at accumsan consectetur, ante erat aliquet ex, nec porttitor lacus ligula nec purus.
      </p>
      <p class="my-3 text-prasset-gray-800">
        Curabitur ac egestas sem. Integer sed elit est. Quisque vestibulum purus neque, sit amet posuere diam imperdiet vitae. Etiam lobortis rutrum quam vel lobortis. Suspendisse ornare tortor sed lorem faucibus, a malesuada felis luctus. Integer pulvinar at odio sit amet auctor. Maecenas vel nisl ac augue interdum placerat. Donec in vulputate diam. Donec vitae purus vitae ipsum egestas dapibus a sit amet libero.
      </p>
      <p class="my-3 text-prasset-gray-800">
        Aliquam vestibulum scelerisque luctus. Suspendisse id interdum massa. Fusce rhoncus iaculis scelerisque. Duis elementum, turpis nec efficitur tincidunt, ante purus pretium tellus, a commodo eros purus sit amet erat. Donec ornare vehicula nisl, vitae suscipit tellus pulvinar sed. Proin sed risus id lectus cursus efficitur tincidunt id libero. Mauris faucibus magna ex, vel ullamcorper nibh efficitur id. Phasellus vel libero sit amet ligula cursus dignissim auctor sed tellus. Quisque malesuada vitae sem non mattis. Vivamus non mauris orci.
      </p>
      <p class="my-3 text-prasset-gray-800">
        Morbi pellentesque mi nec neque semper, sed tempor urna luctus. Praesent scelerisque nulla eu neque ultricies, in pulvinar lacus malesuada. Curabitur condimentum id sem ac facilisis. Etiam ut felis nec velit feugiat finibus. Nulla hendrerit odio sit amet augue vehicula, et eleifend arcu consequat. Nam pulvinar, dolor at pretium consectetur, eros nulla mattis ligula, non aliquam magna turpis semper tortor. Etiam in luctus purus. Etiam pulvinar tempor tellus, eget congue enim egestas non. Ut maximus pulvinar erat a semper.
      </p>
      <h2 class="text-lg text-prasset-green-500">Section</h2>
      <p class="my-3 text-prasset-gray-800">
        In dignissim sem ac tortor commodo maximus. Phasellus ut justo eros. Sed pharetra tincidunt ex, vitae rhoncus magna blandit sit amet. Nulla vitae pulvinar felis. Morbi venenatis, nisi eu congue pulvinar, eros lorem fringilla sem, quis pellentesque sem tellus vel enim. Phasellus sodales magna justo, dapibus iaculis elit accumsan in. Vivamus suscipit tincidunt tellus, viverra laoreet odio tincidunt vitae. Duis id leo id lorem rutrum tempus sit amet et est. Donec rhoncus tortor ut massa tempor, in hendrerit ipsum tristique. Vestibulum ultricies convallis egestas. Integer nec commodo tellus, non tincidunt dui.
      </p>
    </article>
  </div>
</template>

<script>
import MainHeaderAlt from '@/modules/core/views/components/MainHeaderAlt';

export default {
  components: {
    MainHeaderAlt,
  },
};
</script>
